import { PortableText } from '@portabletext/react';
import './SplitLayout.scss';

const SplitLayout = ({ 
    title, 
    body, 
    image, 
    darkMode = false, 
    imageOnRight = true, 
    layoutStyle,
    contentId 
  }) => {

  const flipped = imageOnRight ? '' : 'cms-image-on-left';
  const cmsDarkMode = darkMode ? 'cms-dark-mode' : '';
  const accented = layoutStyle === 'accented' ? 'split-layout--accented' : '';
  const accentedWithShadow = layoutStyle === 'accentedWithShadow' ? 'split-layout--accented-shadow' : '';
  const noImage = !image ? 'no-image' : '';

  return (
    <section className={`split-layout__container ${cmsDarkMode}`}>
      <div className={`container-full-width ${accented} ${accentedWithShadow} ${flipped ? 'accent-flipped' : ''}`}>
        <h1 key={contentId} id={contentId} className="split-layout__title">{title}</h1>
        <div className={`split-layout__content ${noImage}`}>
          <div className='split-layout__text'>
            <PortableText value={body} />
          </div>
          {image && (
            <div className={`split-layout__image ${flipped}`} style={{ backgroundImage: `url(${image})` }}></div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SplitLayout;

