import React from 'react';
import './App.scss';
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import { DocumentViewer } from './Components/DocumentViewer';

const router = createBrowserRouter([
  {
    path: '/company-mfa-document',
    element: <DocumentViewer />
  },
  {
    path: '/',
    element: <Navigate to="/company-mfa-document" />
  }
]);

function App() {

  return (
      <RouterProvider router={router} />
  );
}

export default App;
