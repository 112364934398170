import React from 'react';
import acdsLoading from '../../assets/images/loaders/acds-loading2.svg';
import './AcdsLoader.scss';

const AcdsLoader = () => {
    return (
        <div className="acds-loading">
            <img 
                alt="content is loading" 
                src={acdsLoading} 
                width={60}
                height={150}
            />
        </div>
    );
};

export default AcdsLoader;
