import React,{ useEffect, useState } from "react";
import { client } from "../../sanityClient";
import SplitLayout from "../SanityComponents/SplitLayout";
import { urlFor } from "../../sanityClient";
import AcdsLoader from "../AcdsLoader/AcdsLoader";
import ArrowIcon from "../../assets/images/icons/arrow-right.svg";

export const DocumentViewer = () => {
  const [documentContent, setDocumentContent] = useState([]);

  useEffect(() => {
    async function fetchDocById() {
      const response = await client.fetch(
        `*[ _type == "policyDocument" && documentId == "company-mfa-document"]`
      );
      console.log(response[0].documentTitle);
      setDocumentContent(response?.[0] ?? []);
    }
    fetchDocById();
  }, []);

  if (!documentContent) {
    return <AcdsLoader />;
  }

  return (
    <div className="document-viewer-container">
      <div className="document-viewer">
        <DocumentTitle documentTitle={documentContent.documentTitle} />
        <SeparatorLine />
        <TableOfContents tableOfContents={documentContent.tableOfContents} />
        <SeparatorLine />
        <DocumentSections content={documentContent.content} />
      </div>
    </div>
  );
};

const SeparatorLine = () => <hr style={{ margin: "0px"}} />;

const DocumentSections = ({ content }) => {
  if (!content) return null;
  return content.map((section, index) => (
    <React.Fragment key={section.splitContentId || index}>
      <SplitLayout
        title={section.splitContentHeading ?? ''}
        body={section.splitContentBody}
        image={section.splitContentImage ? urlFor(section.splitContentImage.asset).url() : ''}
        darkMode={section.splitContentLightDarkMode === 'dark'}
        imageOnRight={section.splitContentImagePosition !== 'left'}
        layoutStyle={section.splitContentLayoutStyle}
        contentId={section.splitContentId}
      />
      {index < content.length - 1 && <SeparatorLine/>}
    </React.Fragment>
  ));
};

const TableOfContents = ({ tableOfContents }) => {
  if (!tableOfContents) return null;
  return (
    <div className="table-of-contents">
      <h2>TABLE OF CONTENTS</h2>
      <ol>
        {tableOfContents.map((section) => (
          <li key={section.sectionId}>
            <a href={`#${section.sectionId}`}>
              {section.title}
              <img src={ArrowIcon} alt="Arrow icon" className="arrow-icon" />
            </a>
          </li>
        ))}
      </ol>
    </div>
  );
};

const DocumentTitle = ({ documentTitle }) => {
  if (!documentTitle) return null;
  return (
    <h1 className="heading2">
      {documentTitle.title.map((part, index) => (
        <span key={index} className={part.highlight ? "highlighted-title-text" : "title-text"}>
          {part.text}
        </span>
      ))}
    </h1>
  );
};
