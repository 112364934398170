import {createClient} from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  apiVersion: process.env.REACT_APP_SANITY_API_VERSION,
  token: process.env.REACT_APP_SANITY_READ_TOKEN
})

const builder = imageUrlBuilder(client);
export function urlFor(source) {
  return builder.image(source);
}

export const serializers = {
    types: {
      image: ({ value }) => {
        return (
          <img
            src={urlFor(value).width(600).url()}
            alt={value.alt || ' '}
            style={{ maxWidth: '100%' }}
          />
        );
      },
        link: ({
          value,
          children,
        }) => {
          const { href } = value || {};
          return (
            <a href={href ?? ''} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          );
        },
        small: ({value, children}) => {
          return <small>{children}</small>
        },
        normal: ({children}) => {
          return <p>{children}</p>
        }
    },
  };